/* Descomentar a linha abaixo para debuggar alinhamento */
/* * {
    border: 1px solid #f00 !important;
} */

/* Main classes */
.about-body {
    background: #e9ecef;
    flex: 1 0 auto;
    padding: var(--space) var(--space) 0;
    width: 100%;
}

.about-main-section {
    background: transparent;
    margin: 56px auto 20px;
    padding: 0 15px 40px;
    width: 100%;
    min-height: 680px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: top;
    font-size: calc(1px + 2vmin);
    color: black;
}

.about-body .list {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: center;
}

.about-body .list li {
    /* margin-bottom: 10px; */
    margin-top: 10px;
}



/* Seção Objetivos */
.secao-objetivos {
    background-color: white;
    margin: 0px;
    min-height: calc(100vh - 56px);
}

.secao-objetivos-content {
    padding-left: 0px;
    padding-right: 0px;
}

.about-body .text-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 30px;
    margin: 0 auto 20px;
}

.img-wrap-proposta {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    justify-items: center;
    background-image: url("../../img/about/map.jpg");
    background-color: transparent;
    background-position: center;
    background-size: cover;
    height: 100%;
    min-height: 350px;
    margin: 0 auto 30px;
}

.img-wrap-fullwidth {
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    object-fit: cover;
    overflow: hidden;
    margin: 40px auto 40px;
    padding: 0;
    border-radius: 15px;
    vertical-align: middle;
}

#img-distancia {
    width: 100%;
    object-fit: cover;
    align-self: center;
    justify-self: center;
    vertical-align: middle;
    -webkit-filter: grayscale(20%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(60%);
    /* filter:contrast(1.2); */
    /* filter:saturate(0.9); */
}

.img-wrap-objetivos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    justify-items: center;
    background-image: url("../../img/about/target.jpg");
    background-color: transparent;
    background-position: center;
    background-size: cover;
    height: 75%;
    min-height: 400px;
    min-width: 404px;
    margin: 20px auto 30px;
}


/* Seção DOCS */
.about-body .secao-docs {
    background-color: #0f4c81;
    margin-bottom: 0px;
    /* min-height: 400px; */
}

#github-docs-logo {
    font-size: 2.75rem;
    text-decoration: none;
}

.about-body pre {
    text-align: left;
    display: flex;
    padding: 10px;
    margin: 10px auto 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 90%;
}


/* Seção Sobre o GCE */
.about-body .secao-sobre-gce {
    background-color: white;
    margin: 0 auto 20px;
    padding: 40px 15px 40px;
    width: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: top;
    color: black;
}

/* Seção Equipe */
.secao-equipe {
    margin-top: 0px;
    margin-bottom: 20px;
    padding-top: 30px;
    /* padding-bottom:30px; */
    min-height: 70vh;
    background-color: #e9ecef;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: calc(1px + 1vmin);
    color: black;
}

.secao-equipe .card-deck {
    margin: 0 auto 5px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


/* Seção Contato */
.about-body .secao-contato {
    margin: 0;
    background-color: #0f4c81;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: center;
}


/* Ajustes de Fonte (Fazer media query para versão mobile) */
#about-main-title {
    font-size: 3.2rem;
    /* padding-bottom: 4vh; */
    /* padding-top: 4vh; */
    margin: 56px auto 40px;
}

.about-body .title {
    font-size: 3.2rem;
    padding-bottom: 30px;
    padding-top: 30px;
}

.about-body .subtitle {
    /* padding-bottom: 20px; */
    margin: 20px auto 40px;
    font-size: 2.8rem;
}

.about-body .white-text {
    color: white;
}

.about-body .text {
    font-size: 1.3rem;
}

.about-body .regular-text {
    font-size: 1.2rem;
}

.text-left {
    text-align: left;
}

.inner-container {
    margin: 0;
    padding: 0;
    text-align: center;
}


@media screen and (max-width: 767px) {
    #about-main-title {
        font-size: 2.4rem;
        margin: 6vh auto 5vh;
    }

    .about-body .title {
        font-size: 2.4rem;
        padding-bottom: 30px;
        padding-top: 30px;
    }

    .about-body .subtitle {
        margin: 20px auto 40px;
        font-size: 2.2rem;
    }

    .about-body .white-text {
        color: white;
    }

    .about-body .text {
        font-size: 1.1rem;
    }

    .about-body .regular-text {
        font-size: 1.1rem;
    }

    #contato-title {
        margin-left: 30%;
    }

    .img-wrap-proposta {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        justify-items: center;
        background-image: url("../../img/about/map.jpg");
        background-color: transparent;
        background-position: center;
        background-size: cover;
        height: 100%;
        min-height: 350px;
        margin: 0 auto 30px;
    }

    .img-wrap-objetivos {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        justify-items: center;
        background-image: url("../../img/about/target.jpg");
        background-color: transparent;
        background-position: center;
        background-size: cover;
        height: 75%;
        min-height: 250px;
        min-width: 254px;
        margin: 20px auto 30px;
    }

    #github-docs-logo {
        font-size: 2rem;
        text-decoration: none;
    }

    .about-body pre {
        font-size: 12px;
    }

    .secao-equipe .card-deck {
        margin: 0 auto 0;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 10px;
    }

}

@media (min-width:320px) {
    /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width:481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width:641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width:961px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {
    /* big landscape tablets, laptops, and desktops */
}

@media (min-width:1281px) {
    /* hi-res laptops and desktops */
}


@media screen and (min-width: 768px) and (max-width: 961px) {
    .img-wrap-proposta {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        justify-items: center;
        background-image: url("../../img/about/map.jpg");
        background-color: transparent;
        background-position: center;
        background-size: cover;
        height: 90%;
        min-height: 350px;
        margin: 20px auto 0;
    }
   
    .img-wrap-objetivos {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        justify-items: center;
        background-image: url("../../img/about/target.jpg");
        background-color: transparent;
        background-position: center;
        background-size: cover;
        height: 45%;
        min-height: 350px;
        min-width: 354px;
        margin: 50px auto auto;
    }
}



/* Imagens */
.FGVMapa {
    /* padding:10px; */
    width: inherit;
    max-width: 900px;
    min-width: 300px;
}

.about-body .align-right {
    text-align: right;
    align-items: right;
    justify-content: right;
}
