/* Descomentar a linha abaixo para debuggar alinhamento */
/* * {
    border: 1px solid #f00 !important;
} */
:root {
    --main-gray-color: #e9ecef; 
}


.pesquisa {
    margin: 56px auto 0;
    padding: 0 auto 0;
    background-color: var(--main-gray-color);
    min-height: calc(100vh - 56px);
}

.pesquisa-content {
    flex: 1 0 auto;
    padding: var(--space) var(--space) 0;
    width: 100%;
}

#pesquisa-main-title {
    margin: 6vh auto 5vh;
    font-size: 3.2rem;
}

.pesquisa.text {
    font-size: 1.2rem;
}


.pesquisa.form-container {
    margin: 4vh auto 2vh;
}


.pesquisa.font-small {
    font-size: 1rem;
}



/* Adjustments for mobile version */
@media screen and (max-width: 767px) {
    #pesquisa-main-title {
        font-size: 1.75rem;
    }
    
    .pesquisa.form-container {
        margin: 4vh auto 6vh;
    }

    .pesquisa .search-bar {
        margin: 10px auto 0 auto;
        min-width: 250px;
    }

    #select-radius {
        margin: 0 auto 0 auto;
    }
}

.article {
    border: 10px solid black;
    padding: 16px;
  }
  

