/* Main home container */
:root {
    --main-blue-color: #0f4c81;
}

.home-content {
    flex: 1 0 auto;
    padding: var(--space) var(--space) 0;
    width: 100%;
}

.home-content:after {
    content: '\00a0';
    display: block;
    margin-top: var(--space);
    height: 0;
    visibility: hidden;
}

/* Sections */
#main-home-section {
    margin: 56px 0 0;
    padding-top: 0;
    /* pushed the title a little bit to the top */
    padding-bottom: 100px;
    min-height: calc(100vh - 56px);
    background-color: var(--main-blue-color, #0f4c81);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(1px + 2vmin);
    color: black;
}

.home-content .full-height-gray-section {
    margin: 0;
    min-height: calc(100vh - 50px);
    background-color: linear-gradient(to bottom, rgba(29, 165, 132, .05), rgba(29, 165, 132, .05), rgba(29, 165, 132, 0.02));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(1px + 2vmin);
    color: black;
}

.home-content .full-height-white-section {
    margin: 0;
    min-height: calc(100vh - 50px);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(1px + 2vmin);
    color: black;
}

/* Font adjustments (Fazer media query para versão mobile) */
.home-content .white-text {
    color: white;
    font-family: 'Roboto Slab';
}

.home-content #main-title {
    font-size: 3.75rem;
    padding: 0;
    margin: 0 auto 4vh;
}

.home-content .title {
    font-size: 3.75rem;
    padding: 0;
    margin: 0 auto 2vh;

}

.home-content .main-descricao {
    font-size: 1.4rem;
    padding: 0;
    margin: 0 auto 2vh;
}

.home-content .descricao {
    font-size: 1.3rem;
    padding: 0;
    margin: 0 auto 2vh;
}


.home-content .font-small {
    font-size: 1rem;
    /* padding-top: 10px; */
}

.home-content .text-left {
    text-align: left;
}


/* Reusable elements */
.home-content .custom-link {
    font-size: 1.2rem;
    padding: 0;
}

.home-content .custom-link-light {
    text-decoration: none;
    padding: 0;
    font-size: 1.2rem;
}

.home-content .custom-link-light:hover {
    text-decoration: underline;
    color: rgb(221, 221, 221);
}

.home-content .search-bar {
    width: 70%;
    min-width: 250px;
    position: relative;
    margin: 20px auto 0;
}

.home-content .section-ilustration {
    margin: 0 auto 0;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#home-page-chart-icon {
    font-size: 12rem;
    color: var(--main-blue-color);
    cursor: pointer;
    margin: 1vh auto 0;
    width: 86%;
    min-width: 300px;
    /* filter:grayscale() */
}

#home-page-chart-icon:hover {
    transition: all 0.2s ease-out;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1);
    transform: scale(1.05);
    /* filter: none; */
}

#home-page-map-icon {
    font-size: 8.8rem;
    color: #0f4c81;
    cursor: pointer;
    margin: 1vh auto 1vh;
    width: 50%;
    min-width: 220px;
    /* filter: grayscale(); */
}

#home-page-map-icon:hover {
    transition: all 0.2s ease-out;
    transform: scale(1.05);
    /* filter: none; */
}

#form-control-tooltip {
    margin: 44px auto 4px 6px;
    position: absolute;
}





/* remove botoes de acrescimo na search bar (tipo number) */
.home-content input[type=number]::-webkit-inner-spin-button,
.home-content input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}


/* Adjustments for mobile version */
@media screen and (max-width: 767px) {
    .home-content #main-title {
        font-size: 2.4rem;
        padding: 0;
        margin: 0 auto 4vh;
    }

    .home-content .title {
        font-size: 2.4rem;
        padding: 0;
        margin: 0 auto 2vh;

    }

    .home-content .main-descricao {
        font-size: 1.2rem;
        padding: 0;
        margin: 2vh auto 3vh;
    }

    .home-content .descricao {
        font-size: 1.2rem;
        padding: 0;
        margin: 2vh auto 3vh;
    }


    .home-content .font-small {
        font-size: 1rem;
        margin-top: 20px;
    }

    .home-content .custom-link {
        font-size: 1rem;
    }

    .home-content .custom-link-light {
        text-decoration: none;
        font-size: 1rem;
    }

    .home-content .custom-link-light:hover {
        /* text-decoration: none; */
        color: white;
    }

    .home-content .search-bar {
        width: 70%;
        min-width: 250px;
        margin-left: 0;
    }


    .home-content .section-ilustration {
        text-align: center;
        margin-left: 0px;
    }

    #home-page-chart-icon {
        margin: 3vh auto 3vh;
    }

    #home-page-map-icon {
        font-size: 6.2rem;
        margin: 3vh auto 3vh auto;
        /* calc(70% - 170px) */
    }

    /* .custom-link {
        font-size: 2em;
    } */

    /* .custom-link-light {
      font-size: 2em;
    } */
}