.chart-card {
    font-family: 'Roboto Slab';
    width: calc(100% - 32px);
    min-width: 300px;
    height: auto;  /*para ter altura ajustada ao gráfico, setar para auto */
    float: none;
    display: inline-block;
    /* flex:1; */
    top: 0px;
    position: relative;
    background-color: white;
    border-radius: 10px;
    padding: 5px 10px 0;
    margin: 20px auto 0;
    text-decoration: none;
    z-index: 1;
    overflow: visible;
    border: 1px solid #f2f8f9;
    cursor: pointer;
}

.chart-card:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 4px rgba(0, 143, 251, 0.6);
    /* top: -4px; */
    border: 1px solid #cccccc;
    background-color: white;
}

.chart-card:before {
    background: white;
    margin: 30px auto;
    border-radius: 4px;
    float: none;
    transition: transform 0.2s ease-in-out;
}

.chart-card-body {
    padding: 0;
}

/* Title and Source components */
.chart-card #chart-title {
    text-align: center;
    position: relative;
    font-size: 1rem;
    line-height: 24px;
    font-weight: 600;
    padding: 15px 0 0 10px;
}

.chart-card #chart-source {
    text-align: center;
    position: relative;
    font-size: 0.8rem;
    padding: 0 0 0 10px;
}


/* Chart area */
.chart-wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin: -10px 0 0 0;
}

.text-size {
    font-size: 10px;
  }