/* Defining standard styles */
html {
  font-size: 16px;  /* Definindo para manter a consistência entre browsers (tamanho referẽncia REM)*/
}

body {
  font-family: 'Roboto Slab', sans-serif;
  font-size: 16px;
}

.App {
  text-align: center;
  font-family: 'Roboto Slab';
}

.Site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}