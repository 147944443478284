/* Navbar */
.github-icon {
  font-size: 20px;
}

.navbar {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
}

.nav-font {
  font-size: 1rem;
  /* Fixed size. previous: 1rem (maybe it was too small...) */
  font-weight: 500;
  /* line-height: 1.2; */
  display: flex;
}


.navbar-toggler {
  margin-left: auto;
  padding: 0.25rem 0.75rem;
  /* Ajuste o espaçamento interno conforme necessário */
}

.nav-link:hover .tooltip {
  visibility: visible;
}

#nav-brand {
  position: absolute;
  padding: 0;
  margin: 0;
}

#main-logo {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

#main-flag {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

#main-nav-title {
  font-size: 1.1rem;
}

#github-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0px;

}

#language-select-container {
  display: flex;
  align-items: center;
  margin-top: -10;
}

.select-container {
  display: inline-flex;
  align-items: center;
  margin-left: -15px;

  border: 0px solid #eceaea;
}

.image-container {
  margin-right: -15px;
  margin-left: 30px;
  /* Ajuste conforme necessário */
}



.language-select {
  margin-top: 0px;
  font-size: 0.8rem;
  border-radius: 4px;
  cursor: pointer;
  width: 30px;
  height: 20px;
  margin: 0px;
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  appearance: none;
  text-align: center;
  outline: none;
  margin-right: -5px;

}

.no-margin {
  margin: 0;
}


.language-select:hover {
  text-decoration: underline;
  /* Adicionar sublinhado ao passar o cursor */
}

.language-select.open {
  margin-bottom: 0;
  border: none;
}

.tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #eceaea;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

/* ajuste para telas muito estreitas */
@media only screen and (max-width: 350px) {
  .nav-font {
    font-size: 1.2rem;
  }

  .no-margin {
    margin: 0;
  }

  body {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  #nav-brand {
    position: relative;
    margin-bottom: 10px;
  }

  .fixed-top {
    max-width: 100vw;
    /* Definir a largura máxima como 100% da largura da viewport */
    margin-left: 0px;
  }

  #main-nav-title {
    font-size: 1.2rem;
  }

  #github-nav {
    margin-left: 0;
    margin-top: 10px;
    /* Adicione um espaçamento superior para separar do conteúdo anterior */
  }

  .language-select-container {
    margin-left: auto;
    margin-top: 10px;
    /* Adicione um espaçamento superior para separar do conteúdo anterior */
    flex-direction: row-reverse;
    /* Inverter a direção do flex para colocar a imagem antes da seleção */
  }

  .select-container {
    margin-left: 0px;
    /* Adicione um espaçamento à esquerda para separar da imagem */
  }

  .image-container {
    margin-right: 0px;
    /* Adicione um espaçamento à direita para separar da seleção */
  }

  .navbar-nav {
    margin-left: 15px;
    margin-right: 15px;
  }
}


@media only screen and (max-width: 767px) {
  .nav-font {
    font-size: 1.2rem;
  }

  #nav-brand {
    position: relative;
  }

  #main-nav-title {
    font-size: 1.2rem;
  }

  .no-margin {
    margin: 0;
  }

  #github-nav {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
  }

  .language-select-container {
    margin-left: auto;
  }


  .fixed-top {
    width: 100%;
    left: 0;
    right: 0;
  }

  .navbar {
    padding-left: 0px;
    padding-right: 0px;
  }

  .navbar-nav {
    margin-left: 0px;
    margin-right: 0px;
  }

}