/* Descomentar a linha abaixo para debuggar alinhamento */
/* * {
    border: 1px solid #f00 !important;
} */

:root {
    --main-gray-color: #e9ecef;
}

.estatisticas {
    margin: 56px auto 0;
    padding: 0 auto 0;
    background-color: var(--main-gray-color);
    min-height: calc(100vh - 56px);
}

#estatisticas-main-title {
    margin: 6vh auto 5vh;
    font-size: 3.2rem;
}

.estatisticas .text {
    font-size: 1.2rem;
}


.regular-text {
    font-size: 1.2rem;
}



.text-left {
    text-align: left;
}

/* Charts Section */
.estatisticas .charts-section {
    margin: 10px 5px 10px;
}

.estatisticas .charts-section-title {
    font-size: 1.6rem;
    text-align: left;
    position: relative;
    margin: 20px 20px 0;
    padding: 10px 10px 0px;
}

.estatisticas .charts-section .chart-container {
    padding: 0;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    #estatisticas-main-title {
        /* margin: 6vh auto 5vh; */
        font-size: 2.4rem;
    }

    .about-body .regular-text {
        font-size: 1.0rem;
    }

    .estatisticas .charts-section-title {
        font-size: 1.4rem;
        margin: 7px 7px 0;
        padding: 10px 10px 0px;
    }
}